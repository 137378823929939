import React, { useEffect, useRef, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import tw, { styled } from "twin.macro"
import parse from "html-react-parser"
import Button from "@elements/Button"
import MovingElement from "@elements/MovingElement"
import { useStateContext } from "@context/stateContext"
import { AnimatePresence, motion } from "framer-motion"
import { useCallback } from "react"

const Section = styled(motion.section)`
  ${tw`absolute top-0 z-0 z-10 flex flex-col h-auto fhd:max-h-screen 2xl:h-screen fhd:h-auto lg:h-70vw`}
`
const SectionBackground = styled.span`
  ${tw`absolute top-0 z-10 w-full h-full  md:grid md:grid-cols-2`}
  & .white {
    ${tw`bg-white h-1/2 md:h-auto`}
  }
  & .blue {
    ${tw`h-1/2 md:h-auto bg-blue`}
  }
`
const HeadlineWrap = styled(motion.div)`
  ${tw`relative z-10 flex flex-col items-center pt-24 mt-auto font-black text-center text-black font-ubuntu px-offset md:px-offsetMd lg:px-16 xl:px-24`}
`
const DescriptionGrid = styled(motion.div)`
  ${tw`relative grid grid-cols-none md:grid-cols-2 mt-c60 md:mt-c48 2xl:mt-c60 md:mb-c96 2xl:mb-c120`}
`
const Description = styled(motion.div)`
  ${tw`relative z-10 flex flex-col items-center px-8 pt-6 pb-10 font-normal text-center text-white md:col-start-2 lg:w-3/4 sm:px-12`}
`
const Video = styled(motion.div)`
  ${tw`absolute top-0 left-0 right-0 w-full max-h-screen pt-c120 md:pt-0`}
`
const Spacer = styled(motion.span)`
  ${tw`relative block w-full`}
`

const WpAcfHeaderIntro = () => {
  const floatingSymbolTag = `<oder eintauchen>`
  const { openContactModal } = useStateContext()
  const contentSection = useRef(null)
  const [contentHeight, setContentHeight] = useState(0)
  const [showVideo, setShowVideo] = useState(true)
  const [videoHeight, setVideoHeight] = useState(0)
  const [videoLoaded, setVideoLoaded] = useState(false)
  const [screenWidth, setScreenWidth] = useState(0)

  const video = useRef()
  const data = useStaticQuery(graphql`
    {
      wpPage(databaseId: { eq: 29 }) {
        acfHomeVideo {
          file {
            localFile {
              publicURL
            }
          }
          mobileFile {
            localFile {
              publicURL
            }
          }
          mob480File {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  `)
  const handleScreenWidth = () => {
    setScreenWidth(window.innerWidth)
  }
  const getSectionHeight = useCallback(() => {
    setContentHeight(contentSection.current.getBoundingClientRect().height)
    if (screenWidth !== 0) {
      video.current.querySelector("video").onloadeddata = () => {
        setVideoLoaded(true)
        setVideoHeight(video.current.getBoundingClientRect().height)
      }
      setVideoHeight(video.current.getBoundingClientRect().height)
    }
  }, [contentSection, video, screenWidth])

  useEffect(() => {
    getSectionHeight()
    handleScreenWidth()
    window.addEventListener("resize", getSectionHeight)
    window.addEventListener("resize", handleScreenWidth)
    return () => {
      window.removeEventListener("resize", getSectionHeight)
      window.removeEventListener("resize", handleScreenWidth)
    }
  }, [videoHeight, getSectionHeight, video, screenWidth])

  useEffect(() => {
    if (
      video.current !== null &&
      video.current !== undefined &&
      screenWidth !== 0
    ) {
      video.current.querySelector("video").onended = e => {
        setShowVideo(false)
      }
    }
  }, [video, showVideo, screenWidth])

  const sectionVariants = {
    initial: {
      y: "-100%",
    },
    animate: {
      y: "0%",
      transition: {
        staggerChildren: 0.1,
        staggerDirection: -1,
        ease: "easeOut",
        duration: 0.5,
      },
    },
  }
  const spacerVariants = {
    initial: {
      height: videoHeight,
      transition: {
        duration: 0,
      },
    },
    animate: {
      height: contentHeight,
      transition: {
        type: "tween",
        ease: [0.25, 0.75, 0.4, 1],
        delay: 0.2,
        duration: 0.5,
      },
    },
  }
  const contentElementsVariants = {
    initial: {
      y: -250,
    },
    animate: {
      y: 0,
      transition: {
        staggerChildren: 0.25,
        staggerDirection: 1,
        type: "spring",
        damping: 12,
        stiffness: 80,
      },
    },
  }
  const headlineElementsVariants = {
    initial: {
      marginTop: -400,
    },
    animate: {
      marginTop: 0,
      transition: {
        type: "spring",
        damping: 12,
        stiffness: 80,
      },
    },
  }

  return (
    <>
      <Section
        className=" !absolute w-full"
        initial="initial"
        variants={sectionVariants}
        animate={!showVideo ? "animate" : "initial"}
        ref={contentSection}
        style={{
          y: "-100%",
        }}
      >
        <SectionBackground>
          <div className="white"></div>
          <div className="blue"></div>
        </SectionBackground>
        <HeadlineWrap>
          <motion.h1
            variants={contentElementsVariants}
            className="relative font-bold font-ubuntu text-52special sm:text-64special md:text-72special 2xl:text-80special"
          >
            <motion.span
              variants={headlineElementsVariants}
              className="absolute text-5xl transform -translate-x-full -translate-y-1/2 font-firacode text-blue top-6 md:top-8 2xl:top-10 -left-10"
            >
              {parse(`]|{}->`)}
            </motion.span>
            <motion.span className="relative inline-block text-left uppercase">
              Your Code
              <br />
              for Digital
              <br />
              Branding
              <motion.span
                variants={headlineElementsVariants}
                className="absolute left-0 normal-case transform -translate-x-1/2 -translate-y-1/2 symbol-code text-yellow top-6 md:top-8 2xl:top-10"
              >
                {parse(`&lt;claim&gt;`)}
              </motion.span>
              <motion.span
                variants={headlineElementsVariants}
                className="absolute normal-case transform translate-x-1/2 -translate-y-1/2 symbol-code text-yellow bottom-4 lg:bottom-6 right-14"
              >
                {parse(`&lt;/claim&gt;`)}
              </motion.span>
            </motion.span>
          </motion.h1>
        </HeadlineWrap>

        <DescriptionGrid>
          <Description className="copy">
            <motion.span variants={contentElementsVariants}>
              {parse(
                `->> Wir machen Websites.<== <br />||=<< Aber nicht irgendwelche.>>=|| <br /> { Sondern genau die Website, } <br />|-<<- die zu Ihnen passt. ();`
              )}
            </motion.span>
            <motion.div variants={contentElementsVariants}>
              <Button
                variants={contentElementsVariants}
                onClick={() => openContactModal()}
                buttonText="Jetzt Kontakt aufnehmen"
                buttonHref="/"
                buttonClasses="bg-yellow text-black mr-auto"
              />
            </motion.div>
            <motion.div
              variants={contentElementsVariants}
              className="relative floating-symbol pt-c25"
            >
              <span className="font-normal font-firacode text-symbolSm md:text-symbolMd 2xl:text-symbolXl text-yellow">
                {floatingSymbolTag}
              </span>
              <MovingElement
                speed={0.05}
                direction={{
                  negative: "",
                  axis: "y",
                }}
                content={false}
                className="absolute left-0 right-0 mx-auto transition-all duration-300 ease-linear transform top-c80"
              >
                <span
                  style={{
                    writingMode: "vertical-lr",
                    lineHeight: "1.6",
                  }}
                  className="absolute left-0 right-0 mx-auto text-6xl font-black text-center transition-all duration-300 ease-linear transform scroll-element font-firacode whitespace-nowrap text-yellow top-c80"
                >
                  {parse(`->->>|`)}
                </span>
              </MovingElement>
            </motion.div>
          </Description>
        </DescriptionGrid>
      </Section>
      <Spacer
        className={
          videoLoaded
            ? "z-[-1]"
            : "z-[-1] mb-c120 aspect-w-1 aspect-h-1 md:mb-0 md:aspect-w-16 md:aspect-h-9"
        }
        initial="initial"
        variants={spacerVariants}
        animate={!showVideo ? "animate" : "initial"}
      />
      <AnimatePresence initial={false}>
        <Video
          ref={video}
          initial="initial"
          animate={!showVideo ? "animate" : "initial"}
          exit="initial"
          variants={videoVariants}
        >
          {screenWidth > 768 && screenWidth !== 0 && (
            <video
              id="intro"
              autoPlay
              muted
              playsInline
              src={data.wpPage.acfHomeVideo.file.localFile.publicURL}
              className="object-cover w-full max-h-screen"
            ></video>
          )}
          {screenWidth <= 768 && screenWidth > 480 && screenWidth !== 0 && (
            <video
              id="intro"
              autoPlay
              muted
              playsInline
              src={data.wpPage.acfHomeVideo.mobileFile.localFile.publicURL}
              className="object-cover w-full"
            ></video>
          )}
          {screenWidth <= 480 && screenWidth !== 0 && (
            <video
              id="intro"
              autoPlay
              muted
              playsInline
              src={data.wpPage.acfHomeVideo.mob480File.localFile.publicURL}
              className="object-cover w-full"
            ></video>
          )}
        </Video>
      </AnimatePresence>
    </>
  )
}

const videoVariants = {
  initial: {
    opacity: 1,
    visibility: "visible",
  },
  animate: {
    opacity: 0,
    visibility: "hidden",
    transition: {
      type: "tween",
      ease: [0.25, 0.75, 0.4, 1],
      duration: 0,
    },
  },
}

WpAcfHeaderIntro.propTypes = {
  moduleData: PropTypes.object,
}

export default WpAcfHeaderIntro
